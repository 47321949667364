import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Meta from '../components/meta'

const NotFoundPage = () => (
  <>
    <Meta
      title="Page Not Found"
    />
    <Layout>
      <div className="typography-page limiter short-page">
        <h2 style={{fontSize: 106}}>404</h2>
        <h2>
          Page Not Found
        </h2>
        <p>
          That page doesn't seem to exist. <br/><br/>
          <Link to="/">Go to the Homepage?</Link>
        </p>
      </div>
    </Layout>
  </>
)

export default NotFoundPage
